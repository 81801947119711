import gql from 'graphql-tag';

import QUESTIONNAIRE_RESPONSE_FRAGMENT from 'graph/fragments/questionnaireResponse';

export const questionnaireResponses = gql`
  query questionnaireResponses($questionnaireId: ID) {
    questionnaireResponses(questionnaireId: $questionnaireId) {
      ...questionnaireResponse
    }
  }
  ${QUESTIONNAIRE_RESPONSE_FRAGMENT}
`;

export const questionnaireResponse = gql`
  query questionnaireResponse($id: ID!) {
    questionnaireResponse(id: $id) {
      id
      fhir

      questionnaire {
        id
        fhir
      }
    }
  }
`;
