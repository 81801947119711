import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

export const validateDate = (rule, value, callback) => {
  if (!moment(value).isValid()) {
    callback('The date you entered is not valid');
  } else {
    callback();
  }
};

export const validatePhone = (rule, value, callback) => {
  const placeholder = '(___) ___-____';
  if (!isEmpty(value) && value.includes('_') && value !== placeholder && value.match(/\d/) !== null) {
    callback('The phone you entered is not valid');
  } else {
    callback();
  }
};
