import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Linkify from 'react-linkify';

export default class Display extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { onComplete } = this.props;
    onComplete();
  }

  render() {
    const { text } = this.props;
    return (
      <div className="ma3 w-80 center">
        <p className="tc b f5" style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}
          </Linkify>
        </p>
        <div>This question is read-only.</div>
      </div>
    );
  }
}
