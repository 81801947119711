import React from 'react';

import Tab from './Tab';
import './Tabs.css';

export default function Tabs({ tabs, selectedTab, handleTabChange }) {
  return (
    <ul className="tabs mw8 mt3 w-100 center">
      {tabs.map((tab, i) => {
        let cn = '';
        if (tab.name === selectedTab) {
          cn = 'selected';
        } else {
          cn = Object.hasOwn(tab, 'newCount') && tab.newCount !== 0 ? 'contain-new' : '';
        }

        return (
          <Tab
            key={i}
            label={tab.label}
            name={tab.name}
            className={cn}
            handleTabChange={handleTabChange}
          />
        );
      })}
    </ul>
  );
}
