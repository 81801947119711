import { MessageContext } from 'fluent/compat';
import React from 'react';
import inflection from 'inflection';

const { inflect } = inflection;

export const getQuantityElements = quantities => (
  quantities.map((quantity, i) => (
    <span key={`${quantity.type + i}`} className="dib w4 silver">
      {[quantity.count, quantity.type].join(' ')}
    </span>
  ))
);

const DURATION_UNITS = {
  Second: 'sec',
  Minute: 'min',
  Hour: 'hour',
  Day: 'day',
  Week: 'wk',
  Month: 'mos',
};

export const getExerciseQuantities = (properties) => {
  const {
    count = 1,
    frequency = 1,
    duration = 30,
    durationUnit = 'Second',
    period = 1,
    periodUnit = 'Day',
  } = properties;
  let durationUnits;
  try {
    durationUnits = inflect(DURATION_UNITS[durationUnit], duration);
  } catch (err) {}
  return [
    {
      value: count,
      label: inflect('Rep', count),
    },
    {
      value: duration && durationUnit ? `${duration} ${durationUnits}` : '',
      label: 'Hold',
    },
    {
      value: frequency,
      label: inflect('Set', frequency),
    },
    {
      value: `${period}X`,
      label: `${inflect('Time', period)} Per ${periodUnit}`,
    },
  ];
}

export const getFormDescription = (count, description) => {
  const questions = new MessageContext('en-US');
  questions.addMessages(`
questions = {$count ->
   [1] question
  *[00] questions
}
  `);
  return ([
    <span className="db silver" key="description">{description}</span>,
    <span className="db mt1 silver" key="count">
      {`
        ${count}
        ${questions.format(questions.getMessage('questions'), { count })}
      `}
    </span>,
  ]);
};
