import gql from 'graphql-tag';
import CONVERSATION_FRAGMENT from 'graph/fragments/conversation';

export default gql`
  mutation startConversation($input: ConversationInput!) {
    startConversation(input: { conversation: $input }) {
      conversation {
        ...conversation
      }
      errors {
        message
      }
    }
  }
  ${CONVERSATION_FRAGMENT}
`;
