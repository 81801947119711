import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ReportPanel extends Component {
  static defaultProps = {
    id: '',
    className: '',
    children: <noscript />,
  }

  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  render() {
    const { id, className, title, children } = this.props;
    return (
      <div id={id} className={`ba-ns bg-white pa3 ${className}`}>
        <h3 className="ws-normal">{title}</h3>
        {children}
      </div>
    );
  }
}

export default ReportPanel;
