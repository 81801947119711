import React, { Component, Fragment } from 'react';
import { Tabs } from 'antd';

import Panel from 'common/Panel';
import TitlePanel from 'common/TitlePanel';
import ProfileSettings from './ProfileSettings';
import PasswordSettings from './PasswordSettings';

const { TabPane } = Tabs;

export class Settings extends Component {
  render() {
    return (
      <Fragment>
        <TitlePanel className="pa3 flex items-center justify-center">
          <h2 className="tracked mb0">Settings</h2>
        </TitlePanel>
        <Panel className="flex flex-column">
          <Tabs
            defaultActiveKey="profile"
            onChange={this.handleSetTab}
            tabBarStyle={{ textAlign: 'center' }}
          >
            <TabPane tab="Profile" key="profile">
              <ProfileSettings />
            </TabPane>
            <TabPane tab="Password" key="password">
              <PasswordSettings />
            </TabPane>
          </Tabs>
        </Panel>
      </Fragment>
    );
  }
}

export default Settings;
