import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Button } from '@keethealth/keet-ui';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import queries from 'graph/queries';
import MARK_EDUCATION_AS_COMPLETE from 'graph/mutations/markEducationAsComplete';
import Panel from 'common/Panel';
import CompletedModal from 'common/CompletedModal';
import history from 'utils/history';
import { formatEmbeddedYoutube, getBasePath, findNext } from 'utils/utils';

export function Content({ loading, education, getNextActivity, markEducationAsComplete, educations, match }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);

  const { params: {taskId} } = match;

  useEffect(() => {
    if (education) {
      setComplete(education.status === 'completed');
    }
  }, [education]);


  return (
    <>
      {!loading &&
        <Panel className="overflow-auto">
          <div className="f4 bb pa3 tc-ns tl pl5-ns pr5-ns relative">
            <Link to={getBasePath(history.location.pathname)}>
              <i className="fas fa-arrow-left absolute left-2-ns left-1 h-100 top-0 flex items-center" />
            </Link>
            <div className="tc-ns tl pl5-ns pl4 pr5-ns">
              {education.plannable ? education.plannable.title : null}
            </div>
          </div>
          <div
            className="center pa3 overflow-auto mw8"
            dangerouslySetInnerHTML={{ __html: formatEmbeddedYoutube(education.plannable.body) }}
          />
          {complete ? (
            <div className="flex items-center flex-column mb5">
              <div className="mb3"><i className="primary f2 fas fa-check-circle" /></div>
              <div className="gray">Completed</div>
            </div>
          ) : (
            <Button
              className="center mb5 mt3"
              onClick={handleComplete}
              disabled={complete}
            >
              Complete
            </Button>
          )}
          <CompletedModal isOpen={isModalOpen} onClose={handleEnd} />
        </Panel>
      }
    </>
  );

  function getNext() {
    if (!isEmpty(educations)) {
      const currentIndex = findIndex(educations, { id: taskId });
      const nextActivity = findNext(educations, currentIndex, cpa => cpa.status !== 'completed');

      if (nextActivity) return nextActivity.id;
    }
    // return to beginning
    return null;
  }

  function handleComplete(e) {
    e.preventDefault();
    const basePath = getBasePath(history.location.pathname);
    markEducationAsComplete(education.id);
    const nextActivityId = basePath.includes('my_tasks') ? getNextActivity() : getNext();
    if (isEmpty(nextActivityId)) {
      setIsModalOpen(true);
    } else {
      handleEnd();
    }
  }

  function handleEnd() {
    let basePath = getBasePath(history.location.pathname);
    const nextActivityId = basePath.includes('my_tasks') ? getNextActivity() : getNext();

    if (nextActivityId) {
      basePath = `${basePath}/${nextActivityId}`;
    }
    history.push(basePath);
  }
}

Content.propTypes = {
  loading: PropTypes.bool.isRequired,
  education: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    plannable: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  }),
  getNextActivity: PropTypes.func.isRequired,
  markEducationAsComplete: PropTypes.func.isRequired,
  educations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    plannable: PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      body: PropTypes.string.isRequired,
    }),
  })),
  match: PropTypes.object.isRequired,
};

Content.defaultProps = {
  education: null,
  educations: null,
  carePlanActivity: null,
  getNextActivity: () => {},
}

export default compose(
  graphql(MARK_EDUCATION_AS_COMPLETE, {
    props: ({ mutate }) => ({
      markEducationAsComplete: id => mutate({
        variables: { id },
      }),
    }),
  }),
  graphql(queries.education, {
    props: ({ data: { loading, education } }) => ({
      loading,
      education,
    }),
    options: ({ match: { params: { taskId: id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(queries.educations, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { educations } }) => ({
      educations,
    }),
  }),
)(Content);
