import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import history from 'utils/history';
import PATIENT_RECORD from 'graph/queries/patientRecord';
import Dropdown, { Menu, MenuItem } from 'common/Dropdown';
import { logoutUser } from 'redux/auth/actions';
import MessageBadge from './MessageBadge';

const mapDispatchToProps = dispatch => (
  {
    onLogout: () => { dispatch(logoutUser()); },
  }
);

class HeaderMobile extends Component {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
  }

  handleClick = (info) => {
    const { onLogout } = this.props;
    const { key } = info;
    switch (key) {
      case 'logout':
        onLogout();
        break;
      default:
        history.push(`/${key}`);
    }
  }

  renderTabs = () => {
    const menu = [
      {
        key: 'my_tasks',
        iconClass: 'icon-clipboard-1 mr2',
        title: 'My Tasks',
      },
      {
        key: 'progress',
        iconClass: 'fas fa-chart-bar mr2',
        title: 'Progress',
      },
      {
        key: 'history',
        iconClass: 'fas fa-history mr2',
        title: 'History',
      },
      // {
      //   key: 'education',
      //   iconClass: 'fas fa-book mr2',
      //   title: 'Education',
      // },
      {
        key: 'messages',
        iconClass: 'fas fa-envelope mr2',
        title: (
          <MessageBadge offset={[5, 0]}>
            Messages
          </MessageBadge>
        ),
      },
      {
        key: 'settings',
        iconClass: 'fas fa-cog mr2',
        title: 'Settings',
      },
      {
        key: 'logout',
        iconClass: 'fas fa-power-off mr2',
        title: 'Log Out',
      },
    ];
    return menu.map(props => (
      <MenuItem key={props.key}>
        <div className="flex flex-row">
          <div className="w2">
            <i className={props.iconClass} />
          </div>
          {props.title}
        </div>
      </MenuItem>
    ));
  }

  render() {
    const menu = (
      <Menu onClick={this.handleClick} className="header-login-text">
        {this.renderTabs()}
      </Menu>
    );
    return (
      <Dropdown
        trigger={['click']}
        overlay={menu}
        animation="slide-up"
      >
        <div id="header-profile" className="flex items-center mr2 fw4">
          <div id="header-login-text" className="pr3 pl3 pointer silver w-100 flex justify-end">
            <div className="header-tab flex items-center">
              <MessageBadge count={1} offset={[-1, -1]}>
                <i className="fas fa-bars ba pa2 f3 bw1 br2 pr3 pl3 pointer" />
              </MessageBadge>
            </div>
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  graphql(PATIENT_RECORD, {
    props: ({ data: { patientRecord } }) => ({
      patientRecord,
    }),
  }),
)(HeaderMobile);
