import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'App/app.css';

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK !== 'none'
) {
  const { worker } = require('./mocks/browser'); //eslint-disable-line
  worker.start();
}

ReactDOM.render(<App />, document.getElementById('root'));
