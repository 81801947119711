import gql from 'graphql-tag';

export default gql`
  mutation updateQuestionnaireResponse($input: UpdateQuestionnaireResponseInput!) {
    updateQuestionnaireResponse(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;
