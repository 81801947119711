import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { Button } from '@keethealth/keet-ui';

import { formatPhoneNumber, fieldHasErrors, fieldErrorText } from 'utils/utils';
import { normalizeUser } from 'utils/user';
import TextField from 'common/TextField';
import CURRENT_USER from 'graph/queries/currentUser';
import UPDATE_PROFILE from 'graph/mutations/updatePatient';
import { showNotification } from 'redux/notification';

const mapDispatchToProps = dispatch => (
  {
    notification: () => { dispatch(showNotification('Success', 'success')); },
  }
);

export class ProfileSettings extends PureComponent {
  static defaultProps = {
    currentUser: {
      id: '',
      given: '',
      family: '',
      phone: '',
      email: '',
      username: '',
      addressPostalcode: '',
    },
  }

  static propTypes = {
    currentUser: PropTypes.object,
    updateUser: PropTypes.func.isRequired,
    notification: PropTypes.func.isRequired,
  }

  state = {
    id: '',
    given: '',
    family: '',
    phone: '',
    email: '',
    username: '',
    addressPostalcode: '',
    errors: [],
    isSubmitDisabled: false,
  }

  componentWillReceiveProps(nextProps) {
    const { currentUser } = nextProps;
    if (currentUser) {
      const { id, given, family, phone, email, addressPostalcode, username } = currentUser;
      this.setState({
        id,
        given,
        family,
        phone,
        email,
        addressPostalcode,
        username,
      });
    }
  }

  handleInputChange = name => (value) => {
    if (name === 'phone') {
      this.setState({
        phone: formatPhoneNumber(value),
      });
    } else {
      this.setState({
        [name]: value,
      });
    }
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    this.setState({ isSubmitDisabled: true });
    const { updateUser, notification } = this.props;
    const { given, family, phone, email, addressPostalcode, username } = this.state;
    const res = await updateUser({ given, family, phone, email, addressPostalcode, username });
    this.setState({
      errors: res.data.updateUser.errors,
    });
    if (res.data.updateUser.errors.length === 0) {
      notification();
    }
    this.setState({ isSubmitDisabled: false });
  }

  render() {
    const { given, family, phone, email, addressPostalcode, errors, username, isSubmitDisabled } = this.state;

    return (
      <div className="flex flex-column justify-center items-center flex-auto overflow-auto">
        <form className="w5" onSubmit={this.handleSubmit}>
          <TextField
            id="settings-given"
            type="text"
            label="First Name"
            value={given || ''}
            onChange={this.handleInputChange('given')}
            className="mv3"
            error={fieldHasErrors(errors, 'given')}
            errorText={fieldErrorText(errors, 'given')}
          />
          <TextField
            id="settings-family"
            type="text"
            label="Last Name"
            value={family || ''}
            onChange={this.handleInputChange('family')}
            className="mv3"
            error={fieldHasErrors(errors, 'family')}
            errorText={fieldErrorText(errors, 'family')}
          />
          <TextField
            id="settings-username"
            type="text"
            label="Username"
            value={username || ''}
            onChange={this.handleInputChange('username')}
            className="mv3"
            error={fieldHasErrors(errors, 'username')}
            errorText={fieldErrorText(errors, 'username')}
          />
          <TextField
            id="settings-email"
            type="email"
            label="Email"
            value={email || ''}
            onChange={this.handleInputChange('email')}
            className="mv3"
            error={fieldHasErrors(errors, 'email')}
            errorText={fieldErrorText(errors, 'email')}
          />
          <TextField
            id="settings-phone"
            type="tel"
            label="Phone Number"
            value={phone || ''}
            onChange={this.handleInputChange('phone')}
            className="mv3"
            maxlength="14"
            error={fieldHasErrors(errors, 'phone')}
            errorText={fieldErrorText(errors, 'phone')}
          />
          <TextField
            id="settings-addressPostalcode"
            type="text"
            label="Postal Code"
            value={addressPostalcode || ''}
            onChange={this.handleInputChange('addressPostalcode')}
            className="mv3"
            error={fieldHasErrors(errors, 'addressPostalcode')}
            errorText={fieldErrorText(errors, 'addressPostalcode')}
          />
          <Button
            className="center mv3"
            type="submit"
            disabled={isSubmitDisabled}
          >
            SAVE
          </Button>
        </form>
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  graphql(UPDATE_PROFILE, {
    props: ({ mutate }) => ({
      updateUser: ({ given, family, phone, email, addressPostalcode, username }) => mutate({
        variables: { given, family, phone, email, addressPostalcode, username },
      }),
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { patientRecord, currentUser } }) => normalizeUser({ user: currentUser, patient: patientRecord }),
  }),
)(ProfileSettings);
