import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Table } from 'antd';

import queries from 'graph/queries';
import ActivityIcon from 'common/ActivityIcon';
import TitlePanel from 'common/TitlePanel';
import Panel from 'common/Panel';
import history from 'utils/history';

import './education.css';

const renderCompleted = isCompleted => (isCompleted
  ? <i className="primary f2 fas fa-check-circle" />
  : <i className="primary f2 far fa-circle" />);

export class Education extends Component {
  static defaultProps = {
    educations: [],
  }

  static propTypes = {
    educations: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      plannable: PropTypes.shape({
        __typename: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    })),
    loading: PropTypes.bool.isRequired,
  }

  state = {
    sortedInfo: {},
  }

  onRow = record => ({
    onClick: this.handleRowClick(record),
  })

  getData = () => {
    const { educations, loading } = this.props;
    if (loading) return [];
    return educations.map(education => ({
      key: education.id,
      id: education.id,
      status: education.status,
      type: 'education',
      path: `/education/${education.id}`,
      title: education.plannable.title,
      description: education.plannable.description,
    }));
  }

  handleRowClick = record => (e) => {
    e.preventDefault();
    history.push(`/education/${record.id}`);
  }

  clearAll = () => {
    this.setState({
      sortedInfo: null,
    });
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      sortedInfo: sorter,
    });
  }

  sortByTitle = (a, b) => {
    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
    const nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }

  renderEmptyText = () => (
    <div className="pa4 tc">
      <h3 className="ws-normal">{'You don\'t have any educational materials yet.'}</h3>
      <h4>{'We\'ll let you know when you do.'}</h4>
    </div>
  );

  renderEducationRow = (title, record) => (
    <div className="flex items-center justify-between">
      <div className="items-center tc">
        <ActivityIcon type={record.type} />
      </div>
      <div className="flex-grow-1 ph3">
        <span className="db f4">{title}</span>
        <span className="db f5">{record.description}</span>
      </div>
    </div>
  )

  render() {
    const { loading } = this.props;
    const { sortedInfo } = this.state;
    const columns = [
      {
        title: 'Task Name',
        dataIndex: 'title',
        key: 'title',
        sorter: this.sortByTitle,
        sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
        render: this.renderEducationRow,
      },
      {
        title: 'Completed',
        key: 'status',
        dataIndex: 'status',
        align: 'center',
        render: (_, record) => renderCompleted(record.status === 'completed'),
        width: 105,
      },
    ];

    return (
      <Fragment>
        <TitlePanel className="pa3 flex items-center justify-center">
          <h2 className="tracked mb0">Education</h2>
        </TitlePanel>
        <Panel id="scene-education" className="flex flex-column">
          <div className="w-100 overflow-auto pa3-ns pa0">
            {!loading && this.getData().length === 0
              ? this.renderEmptyText()
              : (
                <Table
                  onRow={this.onRow}
                  columns={columns}
                  dataSource={this.getData()}
                  pagination={false}
                  loading={loading}
                  onChange={this.handleChange}
                  className="pointer"
                />
              )
            }
          </div>
        </Panel>
      </Fragment>
    );
  }
}

export default compose(
  graphql(queries.educations, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { loading, educations } }) => ({
      educations,
      loading,
    }),
  }),
)(Education);
