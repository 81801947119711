import gql from 'graphql-tag';
import EDUCATION_FRAGMENT from '../fragments/education';

export const educations = gql`
query educations {
  educations {
    __typename
    id
    progress
    status
    description
    scheduledAt
    position
    plannable {
      __typename
      ... on Education {
        ...education
      }
    }
  }
}
${EDUCATION_FRAGMENT}
`;

export const education = gql`
query education($id: ID!) {
  education(id: $id) {
    id
    progress
    status
    description
    scheduledAt
    position
    dailyAmount
    scheduleTiming {
      event
    }
    plannable {
      __typename
      ... on Education {
        ...education
      }
    }
  }
}
${EDUCATION_FRAGMENT}
`;
