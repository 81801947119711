import React, { Component } from 'react';
import ReactModal from 'react-modal';

import './modal.css';

class Modal extends Component {
  render() {
    const { children, className } = this.props;
    return (
      <ReactModal
        {...this.props}
        ariaHideApp={false}
        className={className || 'overRideDefaultClass'}
      >
        {children}
      </ReactModal>
    );
  }
}

export default Modal;
