import gql from 'graphql-tag';

export default gql`
  fragment content on ContentInterface {
    active
    author
    body
    contentType
    description
    id
    name
    note
    status
    title
  }
`;
