import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import Logger from 'services/Logger';

import ErrorPage from './ErrorPage';

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    Logger.log({
      error,
      params: { info },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children, history, ...otherProps } = this.props;

    history.listen(() => {
      if (this.state.hasError) {
        this.setState({
          hasError: false,
        });
      }
    });

    if (hasError) {
      return <ErrorPage {...otherProps} />;
    }
    return children;
  }
}

export default withRouter(ErrorBoundary);
