import React from 'react';
import { Badge as AntBadge } from 'antd';
import './Badge.css';

function Badge(props) {
  return (
    <AntBadge {...props} />
  );
}

export default Badge;
