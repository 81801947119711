import gql from 'graphql-tag';
import EXERCISE_FRAGMENT from '../fragments/exercise';

export const exercises = gql`
query exercises {
  exercises {
    id
    progress
    status
    description
    scheduledAt
    position
    properties {
      count
      frequency
      duration
      durationUnit
      period
      periodUnit
    }
    plannable {
      ... on Exercise {
        ...exercise
      }
    }
  }
}
${EXERCISE_FRAGMENT}
`;

export const exercise = gql`
query exercise($id: ID!) {
  exercise(id: $id) {
    id
    progress
    status
    description
    scheduledAt
    position
    dailyAmount
    properties {
      count
      frequency
      duration
      durationUnit
      period
      periodUnit
    }
    scheduleTiming {
      event
    }
    plannable {
      ... on Exercise {
        ...exercise
      }
    }
  }
}
${EXERCISE_FRAGMENT}
`;
