import React from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import ChatGroup from "./ChatGroup";

const DATE_FORMAT_STR = 'MMM-D-YYYY';

function ChatGroups({ messages, patientId }) {
  const days = messages
    .map(message => moment(message.createdAt).format(DATE_FORMAT_STR))
    .filter((value, index, self) => self.indexOf(value) === index);

  let groups = [];
  days.forEach(function groupMessagesByDay(day) {
    const messagesOnDay = messages.filter(message => moment(message.createdAt).format(DATE_FORMAT_STR) === day);
    groups.push(messagesOnDay);
  });

  return groups.map((group, i) => {
    const [mon, day, yr] = days[i].split('-');
    const fmtDate = `${mon} ${day}, ${yr}`;
    return <ChatGroup header={fmtDate} messages={group} patientId={patientId} />
    }
  )
}

ChatGroups.propTypes = {
  messages: PropTypes.array.isRequired,
  patientId: PropTypes.string.isRequired,
};

export default ChatGroups;
