import { reject, reduce, filter, orderBy, forEach } from 'lodash';
import moment from 'moment';
import { compose } from 'redux';
import get from 'lodash/get';

export const filterActive = (carePlans) =>
  filter(carePlans, { status: 'active' });

export const isCurrentDate = (date) => {
  const currentDate = moment();
  return currentDate.isSame(date, 'day');
};

export const countTo = (num, collection, iteratee) => {
  let count = 0;
  /* eslint-disable consistent-return */
  forEach(collection, (el) => {
    if (count === num) {
      return false;
    }

    if (iteratee(el)) {
      count += 1;
    }
  });

  return count === num;
};

export const isCompleted = (activity) => {
  const { scheduleTiming } = activity;
  const period = get(activity, 'properties.period', 1);
  const event = get(scheduleTiming, 'event', []);
  return countTo(period, event, isCurrentDate);
};

export const hideCompleted = (activities) => reject(activities, isCompleted);

export const flattenActivities = (carePlans) =>
  reduce(
    carePlans,
    (result, value) => [...result, ...value.carePlanActivities],
    [],
  );

export const transformActivities = compose(
  hideCompleted,
  flattenActivities,
  filterActive,
);

export const orderByDate = (
  collection,
  fieldName = 'date',
  direction = 'asc',
) =>
  orderBy(
    collection,
    (element) =>
      element[fieldName] ? moment(element[fieldName]).valueOf() : '',
    direction,
  );
