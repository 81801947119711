import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './login.css';

export class LoginPanel extends Component {
  static defaultProps = {
    className: '',
    children: <noscript />,
  }

  static propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  render() {
    const { className, children } = this.props;
    return (
      <div id="login-panel" className={`flex-auto ba bg-white ma3 w-100 center ${className}`}>
        {children}
      </div>
    );
  }
}

export default LoginPanel;
