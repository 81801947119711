import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import history from 'utils/history';
import PATIENT_RECORD from 'graph/queries/patientRecord';
import CURRENT_USER from 'graph/queries/currentUser';
import Dropdown, { Menu, MenuItem, Divider } from 'common/Dropdown';
import { logoutUser } from 'redux/auth/actions';

const mapDispatchToProps = dispatch => (
  {
    onLogout: () => { dispatch(logoutUser()); },
  }
);

class HeaderProfile extends Component {
  static propTypes = {
    patientRecord: PropTypes.shape({
      id: PropTypes.string,
      given: PropTypes.string,
    }),
    currentUser: PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }),
    onLogout: PropTypes.func.isRequired,
  }

  static defaultProps = {
    patientRecord: {
      id: '',
      given: '',
      organization: {
        name: '',
      },
    },
    currentUser: {
      id: '',
      username: '',
    },
  }


  handleClick = (info) => {
    const { onLogout } = this.props;
    const { key } = info;
    switch (key) {
      case 'logout':
        onLogout();
        break;
      default:
        history.push('/settings');
    }
  }

  render() {
    const { currentUser: { username }, patientRecord: { given, organization } } = this.props;
    const menu = (
      <Menu onClick={this.handleClick}>
        <MenuItem key="username" className="inactiveMenuItem">
          <i className="fas fa-user mr2" />
          {username}
        </MenuItem>
        <Divider />
        <MenuItem key="settings">
          <i className="fas fa-cog mr2" />
          Settings
        </MenuItem>
        <MenuItem key="logout">
          <i className="fas fa-power-off mr2" />
          Log Out
        </MenuItem>
      </Menu>
    );
    return (
      <Dropdown
        trigger={['click']}
        overlay={menu}
        animation="slide-up"
      >
        <div id="header-profile" className="flex items-center mr2 fw4">
          <div id="header-login-text" className="pr3 pl3 pointer silver">
            <div className="header-login-text">
              <span className="primary ttc f6">{given}</span>
              <br />
              <span className="primary ttc f6">{organization.name}</span>
              <i className="fas fa-angle-down ml2 fw-1" />
            </div>
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  graphql(PATIENT_RECORD, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { patientRecord } }) => ({
      patientRecord,
    }),
  }),
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      currentUser,
    }),
  }),
)(HeaderProfile);
