import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { normalizeUser } from 'utils/user';
import CURRENT_USER from 'graph/queries/currentUser';
import isEmpty from 'lodash/isEmpty';

import Header from 'common/Header';
import IdleTimer from 'services/IdleTimer';
import Pendo from 'services/Pendo';
import './layout.css';

export const Layout = (props) => {
  const { children, currentUser } = props;
  useEffect(() => {
    IdleTimer.init();

    return function cleanup() {
      IdleTimer.cleanUp();
    };
  }, []);

  useEffect(() => {
    // Init when currentUser changes

    if (!isEmpty(currentUser)) {
      Pendo.init(currentUser);
    }
  }, [currentUser]);

  return (
    <div className="flex flex-column w-100 h-100">
      <Header />
      {children}
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  currentUser: PropTypes.shape({
    id: PropTypes.string,
  }),
};

Layout.defaultProps = {
  children: null,
  currentUser: {},
};

export default compose(
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { patientRecord, currentUser } }) => normalizeUser({ user: currentUser, patient: patientRecord }),
  }),
)(Layout);
