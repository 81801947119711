import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Slider as AntSlider } from 'antd';
import Props from 'utils/proptypes';
import Linkify from 'react-linkify';
import classNames from 'classnames';

export default class Slider extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onIncomplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.number,
      type: Props.questionnaire.answerType,
    }),
    disabled: PropTypes.bool,
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    answer: {},
    disabled: false,
    isAnswerType: false,
    readOnly: false,
  }

  state = {
    value: this.props.answer.value,
  }

  componentDidMount() {
    const { onAnswer, id, onComplete, required } = this.props;
    const { value } = this.state;
    if (typeof value === 'undefined') {
      onAnswer(id, { value: 0, valueType: 'integer' });
      if (!required) {
        onComplete();
      }
    }
  }

  handleChange = (value) => {
    const { onAnswer, id, onComplete, onIncomplete, required } = this.props;
    onAnswer(id, { value, valueType: 'integer' });
    this.setState({
      value,
    });
    if (required && value === undefined) {
      onIncomplete();
    } else {
      onComplete();
    }
  }

  renderSlider = () => {
    const { disabled } = this.props;
    const sliderProps = {
      onAfterChange: this.handleChange,
      defaultValue: this.state.value,
      min: 0,
      max: 10,
      step: 1,
      marks: { 0: 0, 5: 5, 10: 10 },
    };

    return (
      <AntSlider {...sliderProps} disabled={disabled} />
    );
  }

  render() {
    const { text, isAnswerType, readOnly } = this.props;
    return (
      <div className="ma3 w-80 center overflow-x-hidden">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <div className="md-cell--12 center">
          {this.renderSlider()}
        </div>
      </div>
    );
  }
}
