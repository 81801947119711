import React, { Component } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import history from 'utils/history';
import Client from 'App/client';
import AppBody from 'App/AppBody';

class App extends Component {
  render() {
    return (
      <ApolloProvider client={Client.get()}>
        <Provider store={Client.getStore()}>
          <ConnectedRouter history={history}>
            <AppBody />
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    );
  }
}

export default App;
