import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class TitlePanel extends Component {
  static defaultProps = {
    id: '',
    className: '',
    children: <noscript />,
  }

  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  }

  render() {
    const { id, className, children } = this.props;
    return (
      <div id={id} className="mh3 mt3 mw8 w-100 center h4-ns">
        <div className={`ba-ns bb bt mh3-ns mh0 h-100 bg-white ${className}`}>
          {children}
        </div>
      </div>
    );
  }
}

export default TitlePanel;
