import gql from 'graphql-tag';

export default gql`
  fragment message on Message {
    id
    conversationId
    body
    createdAt
    createdBy
    creator {
      id
      firstName
      lastName
    }
    updatedAt
    updatedBy
    updater {
      id
      firstName
      lastName
    }
    readAt
  }
`;
