import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class FormQuestion extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    link: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    link: '/my_tasks',
  }

  render() {
    const { title, children, link, location } = this.props;

    return (
      <div className="overflow-auto relative flex-auto flex flex-column items-center justify-center">
        <div className="f4 pa3 tc bg-primary white w-100 relative">
          {location.pathname.startsWith('/my_tasks') && <Link to={link}>
            <i className="fas fa-arrow-left absolute left-2-ns left-1 h-100 top-0 flex items-center" />
          </Link>}
          <div className="tc-ns tl pl5-ns pl4 pr5-ns">
            {title}
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default withRouter(FormQuestion);
