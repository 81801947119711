import React from 'react';

import BaseContent from "./BaseContent";
import './ContentCardContent.css';

export default function ContentCardContent({ title, icon, children }) {
  return (
    <BaseContent title={title} icon={icon}>
      {children}
    </BaseContent>
  )
}
