import moment from 'moment';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import concat from 'lodash/concat';
import get from 'lodash/get';
import some from 'lodash/some';
import every from 'lodash/every';
import filter from 'lodash/filter';
import slice from 'lodash/slice';
import groupBy from 'lodash/groupBy';
import { AsYouType } from 'libphonenumber-js';

const getValueType = (value) => {
  if (typeof value === 'number') {
    return 'integer';
  }
  return typeof value;
};

export const mapAnswerValues = (answer) => {
  const answerValueType = answer.valueType || getValueType(answer.value);
  const answerValue = answerValueType === 'integer' ? parseInt(answer.value, 10) : answer.value;
  return ({ [`value${startCase(answerValueType)}`]: answerValue });
};

function transformAnswer(answer) {
  let answersList = [];
  if (isArray(answer)) {
    answersList = map(answer, mapAnswerValues);
  } else if (!isEmpty(answer)) {
    answersList = [mapAnswerValues(answer)];
  }
  return answersList;
}

function deepFind(array, predicate, nestedProperty) {
  let found;
  forEach(array, (item) => {
    if (!isEmpty(item[nestedProperty])) {
      found = deepFind(item[nestedProperty], predicate, nestedProperty);
    }
    return !found;
  });
  return found || find(array, predicate);
}

export function deflattenQuestionnaireAnswers(items) {
  const newItems = reduce(items, (result, { linkId, text, definition, answer, type, groupId }) => {
    if (type === 'display') { return result; }
    // If in group, attach to parent
    const answerObj = {
      linkId,
      text,
      definition,
      ...type === 'group' ? {} : { answer: transformAnswer(answer) },
    };
    if (!isEmpty(groupId)) {
      const groupObject = deepFind(result, { linkId: groupId }, 'item');
      (groupObject.item || (groupObject.item = [])).push(answerObj);
      return result;
    }
    return [
      ...result,
      answerObj,
    ];
  }, []);
  return newItems;
}

function mergeAttributes(item, attributes) {
  return reduce(attributes, (result, value, key) => {
    if (isArray(value)) {
      return {
        ...result,
        [key]: concat((result[key] || []), value),
      };
    }
    return {
      ...result,
      [key]: value,
    };
  }, item);
}

export function flattenQuestionnaireItems(items, property = 'item', attributes = {}) {
  return reduce(items, (flattened, item) => {
    const obj = mergeAttributes(item, attributes);
    const children = flattenQuestionnaireItems(
      item[property],
      property,
      {
        enableWhen: item.enableWhen,
        groupId: item.linkId,
      },
    );
    return [
      ...flattened,
      obj,
      ...children,
    ];
  }, []);
}

function isAnswerMatches(questionAnswer, referenceAnswer) {
  const isSingleAnswerMatches = !!get(questionAnswer, 'value') && get(questionAnswer, 'value') === referenceAnswer;
  const isArrayAnswerMatches = isArray(questionAnswer) && some(questionAnswer, answer => answer.value === referenceAnswer);
  if (isArray(questionAnswer)) {
    return isArrayAnswerMatches;
  }
  return isSingleAnswerMatches;
}

function isConditionFulfilled(questionsObject, condition) {
  const linkId = condition.question;
  const questionAnswer = get(questionsObject, `[${linkId}].answer`);
  const referenceAnswer = condition.answerString || condition.answerInteger;
  if (condition.hasAnswer) {
    if (referenceAnswer) {
      return isAnswerMatches(questionAnswer, referenceAnswer);
    }
    return !isEmpty(questionAnswer);
  } else if (condition.hasAnswer === false) {
    return isEmpty(questionAnswer) || isAnswerMatches(questionAnswer, referenceAnswer);
  } else if (!condition.hasAnswer && referenceAnswer) {
    return isAnswerMatches(questionAnswer, referenceAnswer);
  }
  return true;
}

export function isQuestionEnabled(questionsObject, linkId) {
  const question = questionsObject[linkId];
  if (isEmpty(question.enableWhen)) {
    return true;
  }
  const validator = condition => isConditionFulfilled(questionsObject, condition);
  return some(question.enableWhen, validator) && every(question.enableWhen, enableWhen =>
    isQuestionEnabled(questionsObject, enableWhen.question));
}

function isConditionFulfilledR4(questionsObject, condition) {
  const linkId = condition.question;
  const questionAnswer = get(questionsObject, `[${linkId}].answer.value`);
  const referenceAnswer = condition.answerString || condition.answerInteger;
  switch (condition.operator) {
    case 'exists':
      return !isEmpty(questionAnswer);
    case '=':
      return questionAnswer === referenceAnswer;
    case '!=':
      return questionAnswer !== referenceAnswer;
    case '>':
      return questionAnswer > referenceAnswer;
    case '<':
      return questionAnswer < referenceAnswer;
    case '>=':
      return questionAnswer >= referenceAnswer;
    case '<=':
      return questionAnswer <= referenceAnswer;
    default:
      return false;
  }
}

export function isQuestionEnabledR4(questionsObject, linkId) {
  const question = questionsObject[linkId];
  if (isEmpty(question.enableWhen)) {
    return true;
  }
  const validator = condition => isConditionFulfilledR4(questionsObject, condition);
  if (question.enableBehavior === 'any') {
    return some(question.enableWhen, validator);
  }
  if (question.enableBehavior === 'all') {
    return every(question.enableWhen, validator);
  }
  return false;
}

export function isQuestionnaireFinished(questionsObject, questionsCompleted) {
  const nonGroupItems = filter(questionsObject, obj => obj.type !== 'group');
  return !some(nonGroupItems, (question, index) =>
    question.required &&
    isQuestionEnabled(questionsObject, question.linkId) &&
    !questionsCompleted[index]);
}

export function formatBirthDate(date) {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format('YYYY-MM-DD');
}

export function formatPhoneNumber(number) {
  if (number) {
    const asYouType = new AsYouType('US');
    return asYouType.input(number);
  }
  return '';
}

export function findNext(base, index, validator) {
  if (index >= base.length || index < 0 || base.length === 0) return null;

  let subset;
  // if not last index
  if (index !== base.length - 1) {
    // bring up next incomplete activity
    subset = slice(base, index + 1, base.length);
    const item = find(subset, validator);
    if (item) return item;
  }

  // bring up first incomplete activity
  subset = slice(base, 0, index);
  const item = find(subset, validator);
  if (item) return item;

  return null;
}

export function formatEmbeddedYoutube(content) {
  return content ? content.replace(
    / src="https:\/\/www\.youtube\.com\/embed\//g,
    ' allowFullScreen="allowFullScreen"  src="https://www.youtube.com/embed/',
  ) : '';
}

export function arrayWithoutElementAtIndex(arr, index) {
  return arr.filter((value, arrIndex) => index !== arrIndex);
}

export function checkHasStarted(questionIndexInt, questionsCompleted, questionsObject) {
  const currentQuestion = find(questionsObject, { index: questionIndexInt });
  const otherQuestionsCompleted = arrayWithoutElementAtIndex(questionsCompleted, questionIndexInt).filter(Boolean).length > 0;

  if (otherQuestionsCompleted) {
    return true;
  } else if (typeof currentQuestion !== 'undefined' && !isEmpty(currentQuestion.answer)) {
    if (currentQuestion.answer.value === 0 || currentQuestion.answer.value === '') {
      return false;
    }
    return true;
  }
  return false;
}

export function fieldHasErrors(errors, key) {
  const currentErrors = groupBy(errors, error => error.path[1]);
  return !isEmpty(currentErrors[key]);
}

export function fieldErrorText(errors, key) {
  if (fieldHasErrors(errors, key)) {
    const currentErrors = groupBy(errors, error => error.path[1]);
    return currentErrors[key][0].message;
  }
  return '';
}

export function getBasePath(pathname) {
  const paths = pathname.split('/');
  if (paths !== null) {
    return `/${paths[1]}`;
  }
  return '';
}

/*
  Given collection, aggregates value returned by iteratee
  if iteratee returns truthy.
  filterMap([{ a: 1 }, { a: 2 }, { b: 3 }], ({ a }) => a)
  => [1, 2]
*/
export const filterMap = (collection, iteratee) =>
  reduce(
    collection,
    (agg, el) => {
      const val = iteratee(el);
      return val ? [...agg, val] : agg;
    },
    [],
  );

export const getQuestionnaireScores = data => {
  if (!data) {
    return [];
  }
  const { questionnaireResponses } = data;
  const { questionnaireResponseItems, scores } = questionnaireResponses[0];

  const painResult = questionnaireResponseItems.find(item => item.text === 'pain');
  if (!painResult) {
    return scores;
  }

  const itemScores = questionnaireResponseItems.slice(-2);
  const scoreResult = itemScores.map(item => {
    return {
      text: item.text,
      value: item.questionnaireResponseItemAnswers[0].value,
    };
  });
  return scoreResult;
};

export const formatQuestionnaireScores = scores => {
  if (typeof scores === 'number') {
    return `Most Recent Score: ${scores}`;
  }

  const scoreString = scores
    .map(score => {
      return `${score.text.toUpperCase()}: ${score.value}`;
    })
    .join(', ');

  return `Most Recent Score: ${scoreString}`;
};
