import React, { PureComponent } from 'react';
import { DatePicker as MDDatePicker } from 'react-md';

class DatePicker extends PureComponent {
  render() {
    return (
      <MDDatePicker {...this.props} />
    );
  }
}

export default DatePicker;
