import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import filter from 'lodash/filter';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import map from 'lodash/map';

import Panel from 'common/Panel';
import TitlePanel from 'common/TitlePanel';
import queries from 'graph/queries';
import ReportPanel from 'common/ReportPanel';
import ProgressGraph from './ProgressGraph';

class Progress extends Component {
  static propTypes = {
    carePlans: PropTypes.arrayOf(PropTypes.shape({
      carePlanActivities: PropTypes.arrayOf(PropTypes.shape({
        plannable: PropTypes.shape({
          title: PropTypes.string,
        }),
      })),
    })),
  };

  static defaultProps = {
    carePlans: [],
    loading: false,
  }

  getActivities = () => {
    const { carePlans } = this.props;
    const carePlanActivities = carePlans[0] ? carePlans[0].carePlanActivities : [];
    const questionnaires = filter(carePlanActivities, { plannable: { __typename: 'Questionnaire' } });
    const graphedQuestionnaires = filter(questionnaires, questionnaire =>
      get(questionnaire, 'plannable.properties.displayGraph') !== false);
    const groupedQuestionnaires = groupBy(graphedQuestionnaires, 'plannable.id');
    return groupedQuestionnaires;
  }

  renderContent = (group, questionnaireId) => (
    <ReportPanel title={head(group).plannable.title} className="mv3">
      <ProgressGraph
        questionnaireId={questionnaireId}
      />
    </ReportPanel>
  )

  render() {
    const activities = this.getActivities();
    const isEmpty = activities.length === 0;
    return (
      <Fragment>
        <TitlePanel className="pa3 flex items-center justify-center primary">
          <h2>Check out your Progress</h2>
        </TitlePanel>
        <Panel id="scene-progress" className="pa3-ns pa0">
          <div className="w-100 overflow-auto ph4-ns ph0">
            {map(activities, this.renderContent)}
            {isEmpty &&
              <div className="pa4 tc">
                <h4 className="mt4 mb0">{'This is where you will find your progress graphs.'}</h4>
              </div>
            }
          </div>
        </Panel>
      </Fragment>
    );
  }
}

export default compose(
  graphql(queries.carePlansComplete, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { loading, carePlans } }) => ({
      carePlans,
      loading,
    }),
  }),
)(Progress);
