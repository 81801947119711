import gql from 'graphql-tag';
import CARE_PLAN_ACTIVITY_FRAGMENT from 'graph/fragments/carePlanActivity';

export default gql`
  mutation createQuestionnaireResponse($input: CreateQuestionnaireResponseInput!) {
    createQuestionnaireResponse(input: $input) {
      questionnaire {
        ...carePlanActivity,
      }
      errors {
        path
        message
      }
      questionnaireResponse {
        id
        fhir
        patientId
        questionnaireId
        status
        items {
          linkId
          definition
          text
          answer {
            valueString
            valueDate
            valueInteger
            valueTime
            valueCoding
          }
        }
      }
    }
  }
  ${CARE_PLAN_ACTIVITY_FRAGMENT}
`;
