import React from 'react';

import ContentCardContent from "./ContentCardContent";
import ContentCardFooter from "./ContentCardFooter";
import './ContentCard.css';

export default function ContentCard({ footer, children, status, ...rest }) {
  return (
    <div>
      <ContentCardContent {...rest}>
        {children}
      </ContentCardContent>
      <ContentCardFooter status={status}>
        {footer}
      </ContentCardFooter>
    </div>
  )
}
