import React, { useState } from 'react';
import { graphql, compose } from 'react-apollo';
import {Empty, Table} from 'antd';
import flatMap from 'lodash/flatMap';
import queries from 'graph/queries';
import TitlePanel from 'common/TitlePanel';
import Panel from 'common/Panel';
import ContentCard from 'common/ContentCard';
import Tabs from 'common/Tabs';
import history from 'utils/history';
import { orderByDate } from 'utils/carePlans';
import moment from "moment";
import EducationIcon from "../../../assets/svg/EducationIcon";
import get from "lodash/get";
import QuestionnaireIcon from "../../../assets/svg/QuestionnaireIcon";

function History({ loading, carePlans }) {
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedTab, setSelectedTab] = useState('exercise');

  const columns = [
    {
      title: 'Task Name',
      dataIndex: 'title',
      key: 'title',
      sorter: sortByTitle,
      sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
      render: renderRow,
    },
  ];

  return (
    <>
      <TitlePanel className="pa3 flex items-center justify-center">
        <h2 className="tracked mb0">Completed Tasks</h2>
      </TitlePanel>
      <Tabs
        tabs={[
          {
            label: 'Exercises',
            name: 'exercise',
          },
          {
            label: 'Questionnaires',
            name: 'questionnaire',
          },
          {
            label: 'Education',
            name: 'education',
          }
        ]}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
      />
      <Panel id="scene-education" className="flex flex-column">
        <div className="w-100 overflow-auto pa3-ns pa0">
          {!loading && getData().length === 0
            ? renderEmptyText()
            : (
              <Table
                onRow={record => ({
                  onClick: handleRowClick(record),
                })}
                columns={columns}
                dataSource={getData()}
                pagination={false}
                loading={loading}
                onChange={handleChange}
                className="pointer"
              />
            )
          }
        </div>
      </Panel>
    </>
  );

  function getData() {
    if (loading) return [];
    // TODO: Edit graphQL schema so that care plans can be filtered by status so that they only return 'Active' care plans
    const activeCarePlans = carePlans.filter(cp => cp.status === 'active');
    const orderedCarePlans = orderByDate(activeCarePlans, 'startDate', 'desc');
    const carePlanActivities = flatMap(orderedCarePlans, 'carePlanActivities');

    const activities = carePlanActivities.map((activity) => {
      let path = '';
      switch (activity.plannable.__typename) {
        case 'Education':
          path = `/education/${activity.id}`;
          break;
        case 'Questionnaire':
          path = `/completed_questionnaire/${activity.id}`;
          break;
        case 'Exercise':
          path = `/exercise/${activity.id}`;
          break;
        default:
          break;
      }
      return {
        ...activity,
        key: activity.id,
        type: activity.plannable.__typename.toLowerCase(),
        title: activity.plannable.title,
        description: activity.plannable.description,
        path
      }
    });
    const filteredActivities = activities.filter(activity => activity.type === selectedTab)
    return orderByDate(filteredActivities, 'updatedAt', 'desc');
  }

  function handleRowClick(record) {
    return (e) => {
      e.preventDefault();
      history.push(record.path);
    }
  }

  function handleChange(pagination, filters, sorter) {
    setSortedInfo(sorter);
  }

  function handleTabChange(tabName){
    setSelectedTab(tabName);
  }

  function sortByTitle(a, b) {
    const nameA = a.title.toUpperCase(); // ignore upper and lowercase
    const nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  }

  function renderEmptyText() {
    return (
    <div className="pa4 tc">
      <h3 className="ws-normal">{'You don\'t have any educational materials yet.'}</h3>
      <h4>{'We\'ll let you know when you do.'}</h4>
    </div>
  )}

  function renderRow(title, record) {
    const date = moment(record.updatedAt).format('MM/DD/YYYY');
    const { type } = record;
    let icon;
    switch (type) {
      case 'education':
        icon = <EducationIcon width="4em" height="4em" />;
        break;
      case 'exercise':
        icon = get(record.plannable, 'photoUrl') ? <img src={record.plannable.photoUrl} alt="exercise icon" className="mw-100 pa3" /> : <i className="primary f1 icon-dumbbells" />
        break;
      case 'questionnaire':
        icon = <QuestionnaireIcon width="4em" height="4em" />;
        break;
      case 'missing':
        icon = <img src={Empty.PRESENTED_IMAGE_SIMPLE} alt="missing" />;
        break;
      default:
        icon = <noscript />;
    }
    return (
      <ContentCard
        icon={icon}
        title={title}
        footer={
          record.status === 'not_started'
            ? <p style={{ marginBottom: 0 }}>{`Assigned on: ${date}`}</p>
            : <p style={{ marginBottom: 0 }}>{`Completed on: ${date}`}</p>
        }>
      </ContentCard>
    );
  }
}

History.propTypes = {
  sortedInfo: {},
  selectedTab: 'exercise',
};

History.defaultProps = {
  carePlans: [],
}

export default compose(
  graphql(queries.carePlansComplete, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { loading, carePlans } }) => ({
      carePlans,
      loading,
    }),
  }),
)(History);
