import React, { PureComponent } from 'react';
import RCDropdown from 'rc-dropdown';
import './dropdown.css';

class Dropdown extends PureComponent {
  render() {
    return (
      <RCDropdown {...this.props} />
    );
  }
}

export default Dropdown;
