import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import toString from 'lodash/toString';

import { LikertButton, LikertLabel, LikertLowerLabel } from './components';
import './LikertScale.css';

const LikertScale = ({
  text,
  range,
  disabled,
  value,
  onChange,
  leftText,
  rightText,
}) => (
  <div className="ma3 w-80 center overflow-x-hidden tc">
    <LikertLabel text={text} />
    <div className="md-cell--12 center">
      {times(range, (currentIndex) => {
        const isSelected = value === currentIndex;
        return (
          <LikertButton
            key={currentIndex}
            isSelected={isSelected}
            buttonText={toString(currentIndex)}
            disabled={disabled}
            onClick={() => onChange(currentIndex)}
          />
        );
      })}
    </div>
    <LikertLowerLabel leftText={leftText} rightText={rightText} />
  </div>
);

LikertScale.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  range: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
};

LikertScale.defaultProps = {
  disabled: false,
  range: 11,
  value: undefined,
  onChange: undefined,
  leftText: '',
  rightText: '',
};

export default LikertScale;
