import React from "react";
import './BaseContent.css';

export default function BaseContent({ title, icon, children }) {
  return (
    <div className='base-content'>
      <div className="pa2 w4 items-center tc">
        {icon}
      </div>
      <div className='content'>
        <h1 className='content-title'>{title}</h1>
        {children}
      </div>
    </div>
  )
}
