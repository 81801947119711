import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isFinite from 'lodash/isFinite';
import isEmpty from 'lodash/isEmpty';
import toNumber from 'lodash/toNumber';
import find from 'lodash/find';
import map from 'lodash/map';
import { graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Loader } from '@keethealth/keet-ui';
import COMPLETE_NPS_SURVEY from 'graph/mutations/completeNpsSurvey';
import { mapAnswerValues } from 'utils/utils';
import { transformQuestionnaireResponse } from 'utils/questionnaires';
import Logger from 'services/Logger';
import update from 'immutability-helper';
import { parse } from 'qs';
import Client from 'App/client';
import queries from 'graph/queries';
import NPSRenderer from './NPSRenderer';

export class NPS extends PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    completeNpsSurvey: PropTypes.func,
    nps: PropTypes.shape({
      encounter: PropTypes.shape({
        organization: PropTypes.shape({
          name: PropTypes.string,
          settings: PropTypes.shape({
            logoMarketing: PropTypes.string,
          }),
        }),
      }),
      trackable: PropTypes.oneOfType([
        PropTypes.shape({
          fhir: PropTypes.object,
        }),
      ]),
    }),
    history: PropTypes.shape({
      location: PropTypes.shape({
        search: PropTypes.string,
      }),
    }).isRequired,
  };

  static defaultProps = {
    nps: {},
    completeNpsSurvey: () => {},
    loading: false,
  };

  static getDerivedStateFromProps(props, state) {
    const questionnaireResponse = get(props, 'nps.trackable');
    const questionnaire = get(questionnaireResponse, 'questionnaire');
    if (questionnaire) {
      const { questionsObject = {} } = state;

      const filled = reduce(
        questionnaire.fhir.item,
        (answers, item) => ({
          ...answers,
          [item.linkId]: {
            ...item,
            ...questionsObject[item.linkId],
          },
        }),
        questionsObject,
      );

      return {
        ...state,
        questionsObject: filled,
      };
    }

    return state;
  }

  state = {
    questionsObject: {},
    submitting: false,
    errors: [],
  };

  componentDidMount() {
    this.setHeaders();

    const score = this.getScore();
    if (isFinite(score)) {
      this.setScore(score);
    }
  }

  componentDidUpdate(prevProps) {
    if (isEmpty(prevProps.nps) && this.props.nps) {
      const score = this.getScore();
      if (isFinite(score)) {
        this.setScore(score);
      }
    }
  }

  setHeaders = () => {
    const signatureFromStorage = window.sessionStorage.getItem('@keethealth/signature');

    Client.setHeaders({
      Authorization: `Basic ${signatureFromStorage}`,
    });
  };

  getScore = () => {
    const { score } = this.getParams();
    return toNumber(score);
  };

  setScore = async (score) => {
    try {
      const { questionsObject } = this.state;
      const { completeNpsSurvey } = this.props;
      const questionnaireResponse = this.getQuestionnaireResponse() || {};
      const questionnaire = questionnaireResponse.questionnaire;

      if (questionnaire) {
        const scoreField = find(questionnaire.fhir.item, { type: 'integer' });
        const newObject = update(questionsObject, {
          [scoreField.linkId]: { answer: { $set: {
            value: score,
            valueType: 'integer',
          } } },
        });

        await completeNpsSurvey({
          questionnaireResponseId: questionnaireResponse.id,
          items: newObject,
        });

        this.setState({ questionsObject: newObject });
      }
    } catch (e) {
      Logger.log({ error: e });
    }
  };

  getQuestionnaireResponse = () => {
    const { nps } = this.props;
    return get(nps, 'trackable');
  };

  getParams = () => {
    const { history } = this.props;

    return (
      parse(history.location.search, {
        ignoreQueryPrefix: true,
      }) || {}
    );
  };


  handleAnswer = (questionId, questionAnswer) => {
    const { questionsObject } = this.state;
    this.setState({
      questionsObject: update(questionsObject, {
        [questionId]: { answer: { $set: questionAnswer } },
      }),
    });
  };

  handleSubmit = async (values) => {
    const { completeNpsSurvey } = this.props;
    const questionnaireResponse = this.getQuestionnaireResponse();
    this.setState({ submitting: true });

    let errors = [];

    try {
      const response = await completeNpsSurvey({
        questionnaireResponseId: questionnaireResponse.id,
        items: values,
      });

      errors = response.errors || [];
    } catch (e) {
      errors = [e];
    }

    this.setState({
      submitting: false,
      errors,
      ...(isEmpty(errors) ? { isComplete: true } : {}),
    });
  };

  render() {
    const { nps, loading } = this.props;
    const { questionsObject, submitting, isComplete } = this.state;

    const questionnaireResponse = this.getQuestionnaireResponse();
    const organization = get(nps, 'encounter.organization');

    return (
      <Fragment>
        {loading ? (
          <Loader color="primary" />
        ) : (
          <NPSRenderer
            questionnaire={questionnaireResponse.questionnaire}
            answers={questionsObject}
            onAnswer={this.handleAnswer}
            onSubmit={this.handleSubmit}
            loading={submitting}
            isComplete={isComplete}
            organization={organization}
          />
        )}
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  graphql(queries.npsTrackers, {
    props: ({ data: { loading, nps } }) => {
      if (loading || isEmpty(nps)) {
        return {
          loading,
          nps: null,
        };
      }

      try {
        const npsSurvey = {
          ...nps,
          trackable: transformQuestionnaireResponse(nps.trackable),
        };

        return {
          loading,
          nps: npsSurvey,
        };
      } catch (e) {
        return {
          loading,
          nps: null,
        };
      }
    },
    options: ({
      match: {
        params: { npsId },
      },
    }) => ({
      variables: { id: npsId },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(COMPLETE_NPS_SURVEY, {
    props: ({ mutate }) => ({
      completeNpsSurvey: ({ questionnaireResponseId, items }) => {
        const withAnswers = reduce(items, (sum, value, key) => {
          if (value.answer) {
            return {
              ...sum,
              [key]: value,
            };
          }

          return sum;
        }, {});


        const mappedItems = map(withAnswers, item => ({
          linkId: item.linkId,
          answer: [mapAnswerValues(item.answer)],
        }));
        return mutate({
          variables: {
            input: {
              questionnaireResponseId,
              items: mappedItems,
            },
          },
        });
      },
    }),
  }),
)(NPS);
