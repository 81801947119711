import gql from 'graphql-tag';

import MESSAGE_FRAGMENT from './message';

export default gql`
  fragment conversation on Conversation {
    id
    topic
    sender {
      id
      firstName
      lastName
    }
    receiver {
      id
      firstName
      lastName
    }
    messages {
      ...message
    }
  }
  ${MESSAGE_FRAGMENT}
`;
