import React from 'react';
import { Badge } from 'antd';
import './ContentCardFooter.css';

export default function ContentCardFooter({ children, status }) {
  return (
    <div className="content-card-footer">
      {children}
      {status === 'not_started' && (
        <div className="content-card-new">
          <Badge count={'NEW'} style={{ backgroundColor: '#da007b' }} />
        </div>
      )}
    </div>
  );
}
