import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cN from 'classnames';
import map from 'lodash/map';
import { Notification } from '@keethealth/keet-ui';

import { hideNotification, getNotifications } from 'redux/notification';

class NotificationContainer extends Component {
  static propTypes = {
    notifications: PropTypes.object.isRequired,
    onHide: PropTypes.func.isRequired,
  }

  renderNotifications = () => {
    const { notifications, onHide } = this.props;

    return map(notifications, (notification, id) => {
      let display = 'inherit';
      if (notification.status === 401 || notification.status === 422) {
        display = 'none';
      }
      return (
        <div style={{ display }}>
          <Notification
            key={id}
            type={notification.type}
            text={notification.text}
            id={id}
            onHide={onHide}
            status={notification.status}
            url={notification.url}
            timeout={3}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <div className={cN('fixed top-2 right-2-ns right-0 white br1 w-100 mt4')} style={{ maxWidth: '438px' }}>
        {this.renderNotifications()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state),
});

const mapDispatchToProps = {
  onHide: hideNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
