import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'antd';
import find from 'lodash/find';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import Linkify from 'react-linkify';
import classNames from 'classnames';
import './SingleChoice.css';

const RadioGroup = Radio.Group;

export default class SingleChoice extends Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
    option: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      valueType: PropTypes.string,
    })).isRequired,
    id: PropTypes.string.isRequired,
    onComplete: PropTypes.func.isRequired,
    onAnswer: PropTypes.func.isRequired,
    required: PropTypes.bool.isRequired,
    onNext: PropTypes.func.isRequired,
    answer: PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      valueType: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    isAnswerType: PropTypes.bool,
    readOnly: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    isAnswerType: false,
    answer: {
      value: '',
    },
    readOnly: false,
  }

  state = {
    value: this.props.answer.value,
  }

  componentDidMount() {
    const { onAnswer, id, onComplete, required } = this.props;
    const { value } = this.state;
    if (typeof value === 'undefined' || value === '') {
      onAnswer(id, {});
      if (!required) {
        onComplete();
      }
    }
  }

  options = this.props.option;

  // value is index of question
  handleSelect = ({ target: { value } }) => {
    const { onAnswer, id, onComplete } = this.props;
    const option = find(this.options, ['value', value]);
    onAnswer(id, option);
    this.setState({
      value,
    });
    onComplete();
  }

  handler = debounce(() => {
    this.props.onNext();
  }, 500);

  handleNext = () => {
    this.handler();
  }

  renderOptions = () => map(this.options, (option) => {
    const value = option.value || option.valueString || option.valueInteger;
    return (
      <Radio key={value} className="radioStyle" value={value} onClick={this.handleNext}>
        {value}
      </Radio>
    );
  });

  render() {
    const { text, disabled, isAnswerType, readOnly } = this.props;
    const { value } = this.state;

    return (
      <div className="ma3 w-80 center">
        <p className={classNames('b f5', isAnswerType ? 'tl' : 'tc')} style={{ overflowWrap: 'break-word' }}>
          <Linkify properties={{ target: '_blank' }}>
            {text}{readOnly && ':'}{isAnswerType && !readOnly && <i className="fas fa-edit ml3" />}
          </Linkify>
        </p>
        <RadioGroup className="w-100 tl" onChange={this.handleSelect} value={value} size={'small'} disabled={disabled}>
          {this.renderOptions()}
        </RadioGroup>
      </div>
    );
  }
}
