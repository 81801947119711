import React from 'react';
import { Form, Input, Icon, Select } from 'antd';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import InputMask from 'react-input-mask';

const FormItem = Form.Item;
const { Option } = Select;

const LoginFormItem = (props) => {
  const { name, rules, icon, placeholder, type, getFieldDecorator, label, errors, disabled, autoFocus, style = {} } = props;
  let submitErrors = [];
  if (errors) {
    submitErrors = groupBy(errors, 'id');
  }

  let FormInput = (
    <Input
      size="large"
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      prefix={icon && <Icon type={icon} />}
      autoFocus={autoFocus}
    />
  );

  if (type === 'password') {
    FormInput = (
      <Input.Password
        size="large"
        placeholder={placeholder}
        type={type}
        icon={icon}
        prefix={icon && <Icon type={icon} />}
      />
    );
  } else if (type === 'date') {
    FormInput = (
      <InputMask className="ant-input ant-input-lg" mask="99/99/9999" placeholder="MM/DD/YYYY" />
    );
  } else if (type === 'phone') {
    FormInput = (
      <InputMask className="ant-input ant-input-lg" mask="(999) 999-9999" placeholder="(123) 456-7890" />
    );
  } else if (type === 'gender') {
    const GENDER_OPTIONS = ['male', 'female', 'other'];
    FormInput = (
      <Select size="large">
        {GENDER_OPTIONS.map(gender => (
          <Option value={gender}>
            {startCase(gender)}
          </Option>
        ))}
      </Select>
    );
  }

  return (
    <FormItem label={label || placeholder} style={style}>
      {getFieldDecorator(name, { rules })(FormInput)}
      <div>
        {errors && submitErrors[name] ? submitErrors[name][0].title : ''}
      </div>
    </FormItem>
  );
};

export default LoginFormItem;
