import { showNotification } from 'redux/notification';
import history from 'utils/history';
import get from 'lodash/get';
import { nanoid } from 'nanoid';
import { codeMessage } from 'utils/errors';
import { authorize, checkForResponse, signOut } from 'services/auth';
import ApolloClient from 'App/client';
import Pendo from 'services/Pendo';

import { getStateFromStorage, setStateInStorage } from 'utils/router';

const loginSuccess = () => ({
  type: 'LOGIN_USER',
});

const generateState = () => {
  const state = nanoid();
  const { location } = history;
  const referrer = get(location, 'state.referrer');
  if (referrer) {
    const values = {
      history: {
        url: referrer,
      },
    };
    setStateInStorage(state, values);
  }

  return state;
};

export const loginUser = (payload = {}) => async (dispatch) => {
  try {
    dispatch({ type: 'INITIATE_AUTHORIZATION' });
    await authorize({ ...payload, state: generateState() });
  } catch (e) {
    const status = e.status;
    dispatch(showNotification(codeMessage[status], 'error', status));
  }
};

export const checkAuthorizationCallback = () => async (dispatch) => {
  try {
    dispatch({ type: 'CHECKING_AUTHORIZATION_RESPONSE' });
    const { request } = await checkForResponse();
    const { state } = request;
    dispatch(loginSuccess());

    const previousState = getStateFromStorage(state);
    const redirectLink = get(previousState, 'history.url', '/my_tasks');
    history.push(redirectLink);
  } catch (e) {
    dispatch({ type: 'AUTHORIZATION_FAILURE', payload: e.message });
    history.push('/');
    dispatch(showNotification(e.message, 'error', e.status));
  }
};

const logoutSuccess = () => ({
  type: 'LOGOUT_USER',
});

export const logoutUser = (redirect = true) => (dispatch) => {
  signOut().then(() => {
    dispatch(logoutSuccess());
    Pendo.reset();
    if (redirect) {
      ApolloClient.clearStore();
      history.push('/login');
    }
  });
};
