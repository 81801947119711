import gql from 'graphql-tag';

export const questionnaires = gql`
  query questionnaires {
    questionnaires {
      id
      carePlanId
      progress
      status
      dailyAmount
      quantity
      description
      scheduledAt
      position
      plannable {
        __typename
        ... on Questionnaire {
          __typename
          id
          fhir
        }
      }
    }
  }
`;

export const questionnaire = gql`
  query questionnaire($id: ID!) {
    questionnaire(id: $id) {
      id
      carePlanId
      progress
      status
      dailyAmount
      quantity
      description
      scheduledAt
      position
      plannable {
        __typename
        ... on Questionnaire {
          __typename
          id
          fhir
        }
      }
    }
  }
`;
