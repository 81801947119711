import React from 'react';
import { Redirect } from 'react-router-dom';

import { Register, Login, Authorize, Confirm } from 'scenes/Login';
import CarePlan, { CarePlanActivity } from 'scenes/CarePlan';
import Progress from 'scenes/Progress';
import History from 'scenes/History';
import Education, { Content } from 'scenes/Education';
import Messages from 'scenes/Messages';
import Settings from 'scenes/Settings';
import Questionnaire, { CompletedQuestionnaire } from 'scenes/Questionnaire';
import Task from 'scenes/TaskDetail';
import NPS from 'scenes/NPS';
import QRTask from 'scenes/QRTask';
import SignatureHandler from 'scenes/SignatureHandler';
import Error404 from 'common/Error404';

const routes = [
  {
    path: '/',
    exact: true,
    isPublic: true,
    render: () => <Redirect to="/login" />,
  },
  {
    path: '/authorize',
    exact: true,
    isPublic: true,
    component: Authorize,
  },
  {
    path: '/confirm/:id',
    exact: true,
    isPublic: true,
    component: Confirm,
  },
  {
    path: '/login',
    exact: true,
    isPublic: true,
    component: Login,
  },
  {
    path: '/register',
    exact: true,
    isPublic: true,
    component: Register,
  },
  {
    path: '/my_tasks',
    exact: true,
    component: CarePlan,
  },
  {
    path: '/my_tasks/:taskId/:questionIndex?',
    exact: true,
    component: CarePlanActivity,
  },
  {
    path: '/progress',
    exact: true,
    component: Progress,
  },
  {
    path: '/education',
    exact: true,
    component: Education,
  },
  {
    path: '/education/:taskId',
    exact: true,
    component: Content,
  },
  {
    path: '/history',
    exact: true,
    component: History,
  },
  {
    path: '/messages',
    exact: true,
    component: Messages,
  },
  {
    path: '/settings',
    exact: true,
    component: Settings,
  },
  {
    path: '/exercise/:taskId',
    exact: true,
    component: Task,
  },
  {
    path: '/questionnaire/:questionnaireId',
    exact: true,
    component: Questionnaire,
  },
  {
    path: '/completed_questionnaire/:taskId',
    exact: true,
    component: CompletedQuestionnaire,
  },
  {
    path: '/qr_task/:resourceId/:signature?',
    exact: true,
    isPublic: true,
    component: props => <SignatureHandler {...props} path="qr_tasks" suffix="0" />,
  },
  {
    path: '/qr_tasks/:taskId/:questionIndex?',
    exact: true,
    isPublic: true,
    component: QRTask,
  },
  {
    path: '/nps/:resourceId',
    exact: true,
    isPublic: true,
    component: props => <SignatureHandler {...props} path="nps_survey" />,
  },
  {
    path: '/nps_survey/:npsId',
    exact: true,
    isPublic: true,
    component: NPS,
  },
  {
    exact: false,
    component: Error404,
  },
];

export default routes;
