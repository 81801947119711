import AirbrakeClient from 'airbrake-js';

const {
  REACT_APP_AIRBRAKE_ID,
  REACT_APP_AIRBRAKE_KEY,
  REACT_APP_DEPLOY_ENV,
} = process.env;

const client = new AirbrakeClient({
  projectId: REACT_APP_AIRBRAKE_ID,
  projectKey: REACT_APP_AIRBRAKE_KEY,
  environment: REACT_APP_DEPLOY_ENV,
});

export default class Logger {
  static log(...params) {
    if (REACT_APP_DEPLOY_ENV === 'local') {
      /* eslint-disable-next-line */
      console.log('Airbrake Logger', ...params);
    } else {
      client.notify(...params);
    }
  }
}
