import React from 'react';
import LikertScale from './LikertScale';
import Text from './Text';

const Question = (props) => {
  const { answer, onAnswer, question } = props;

  const questionProps = {
    text: question.text,
    id: question.linkId,
    required: question.required,
    range: question.range,
    onAnswer,
    answer,
  };

  switch (question.type) {
    case 'integer':
      return (
        <LikertScale
          {...questionProps}
          value={props.answer}
          leftText="Not Likely"
          rightText="Very Likely"
          onChange={(value) =>
            onAnswer(question.linkId, { value, valueType: 'integer' })
          }
        />
      );
    case 'text':
      return (
        <Text
          {...questionProps}
          value={props.answer}
          onChange={(updatedValue) =>
            onAnswer(question.linkId, {
              value: updatedValue,
              valueType: 'string',
            })
          }
        />
      );
    default:
      break;
  }
  return <noscript key="noscript" />;
};

export default Question;
