import React from 'react';

import './QuestionnaireContent.css';
import {getQuestionnaireScores, formatQuestionnaireScores} from "../../../../utils/utils";

export default function QuestionnaireContent(props) {
  return (
    <div>
      <p>{props.status === 'completed' && formatQuestionnaireScores(getQuestionnaireScores(props))}</p>
    </div>
  )
}
