import React from 'react';

import './EducationContent.css';

export default function EducationContent(props) {
  return (
    <div>
    </div>
  )
}
