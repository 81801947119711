import moment from 'moment';
import isFunction from 'lodash/isFunction';
import isBoolean from 'lodash/isBoolean';

/*
  Generates a PropType.
  Validator function should return true if the prop is valid.

  e.g.
  const StringProp = generateProp(arg => typeof arg === 'string')
 */
export const generateProp = validator => (props, propName, componentName) => {
  if (!isFunction(validator)) {
    return new Error('Argument must be a function.');
  }

  const res = validator(props[propName]);

  if (!isBoolean(res)) {
    return new Error('Function argument must return a boolean type.');
  }

  if (!res) {
    return new Error(
      `Invalid prop ${propName} supplied to ${componentName}. Validation failed.`,
    );
  }

  return null;
};

export const date = generateProp(prop => moment(prop).isValid());
