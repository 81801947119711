import gql from 'graphql-tag';

export default gql`
  query patientRecord {
    patientRecord {
      given
      active
      address
      addressPostalcode
      email
      family
      gender
      id
      name
      phone
      organization {
        id
        name
        subdomain
      }
      creator {
        accountId
        id
      }
    }
  }
`;
