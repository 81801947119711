import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { SizeMe } from 'react-sizeme';
import { withRouter } from 'react-router-dom';
import get from 'lodash/get';

import queries from 'graph/queries';

import Panel from 'common/Panel';
import { getQuestionsObject } from 'utils/form';
import { flattenQuestionnaireItems } from 'utils/utils';
import FormQuestion from './FormQuestion';
import FormSummary from './FormSummary';
import '../Questionnaire.css';

export class Questionnaire extends Component {
  static propTypes = {
    carePlanActivity: PropTypes.shape({
      plannable: PropTypes.shape({
        fhir: PropTypes.shape({
          identifier: PropTypes.arrayOf(PropTypes.object),
          name: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          purpose: PropTypes.string.isRequired,
          item: PropTypes.arrayOf(PropTypes.shape({
            linkId: PropTypes.string.isRequired,
            definition: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
            type: PropTypes.oneOf(['integer', 'string', 'text', 'choice', 'display', 'group']),
            readOnly: PropTypes.bool.isRequired,
            repeats: PropTypes.bool.isRequired,
            required: PropTypes.bool.isRequired,
            option: PropTypes.arrayOf(PropTypes.shape({
              valueInteger: PropTypes.string,
              valueString: PropTypes.string,
              extension: PropTypes.arrayOf(PropTypes.shape({
                uri: PropTypes.string,
                valueDecimal: PropTypes.string,
              })),
            })),
          })),
        }),
      }),
    }),
    loading: PropTypes.bool.isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        taskId: PropTypes.string,
      }),
    }).isRequired,
  }

  static defaultProps = {
    carePlanActivity: {},
  }

  static getDerivedStateFromProps(props, state) {
    const {
      carePlanActivity,
      loading,
    } = props;

    if (!loading && carePlanActivity) {
      const flattenedItems = get(carePlanActivity, 'plannable.fhir.item');
      return {
        ...state,
        questionsObject: getQuestionsObject(flattenedItems, carePlanActivity.questionnaireResponses),
      };
    }
    return {
      ...state,
    };
  }

  state = {
    questionsCompleted: [],
    questionsObject: {},
    isModalOpen: false,
    scrolled: false,
    isComplete: false,
  };

  componentDidMount = () => {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleScroll);
  };

  handleScroll = () => {
    this.setState({
      scrolled: true,
    });
  };

  renderScrollIndicator = (size) => {
    const { scrolled } = this.state;
    return (
      <Fragment>
        {size.height + 60 > document.documentElement.clientHeight && !scrolled &&
          <div className="flex justify-center center-align">
            <div className="absolute tc bg-white pa3 w-100 flex items-center justify-center scroll-indicator" style={{ bottom: (size.height + 77) - document.documentElement.clientHeight }}>
              <span>Scroll </span>
              <div className="br-100 h2 w2 flex justify-center items-center ml1">
                <i className="fas fa-chevron-down" />
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }

  render() {
    const {
      carePlanActivity,
      loading,
      match: { params: { taskId } },
    } = this.props;

    let title = 'Questionnaire';
    if (loading) return null;

    if (taskId && carePlanActivity) {
      title = carePlanActivity.plannable.fhir.title;
    }
    const {
      questionsObject,
    } = this.state;

    return (
      <Fragment>
        <SizeMe monitorHeight>
          {({ size }) => (
            <Panel id="scene-form" className="flex flex-column h-100 relative">
              <FormQuestion title={title} link="/history">
                <FormSummary
                  readOnly
                  questionnaireId={taskId}
                  questionsObject={questionsObject}
                  handleMarkAsComplete={this.handleMarkAsComplete}
                />
              </FormQuestion>
              {this.renderScrollIndicator(size)}
              <div className="flex items-center flex-column mb5">
                <div className="mb3"><i className="primary f2 fas fa-check-circle" /></div>
                <div className="gray">Completed</div>
              </div>
            </Panel>
          )}
        </SizeMe>
      </Fragment>
    );
  }
}

export default compose(
  withRouter,
  graphql(queries.carePlanActivity, {
    props: ({ data: { loading, carePlanActivity } }) => {
      if (carePlanActivity) {
        const fhir = JSON.parse(carePlanActivity.plannable.fhir);
        return {
          loading,
          carePlanActivity: {
            ...carePlanActivity,
            plannable: {
              ...carePlanActivity.plannable,
              fhir: {
                ...fhir,
                item: flattenQuestionnaireItems(fhir.item),
              },
            },
          },
        };
      }
      return {
        loading,
        carePlanActivity,
      };
    },
    options: ({ match: { params: { taskId: id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
)(Questionnaire);
