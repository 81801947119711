import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@keethealth/keet-ui';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { logoutUser } from 'redux/auth/actions';
import { connect } from 'react-redux';
import errorImage from 'assets/images/error404.png';
import './error404.css';

const mapDispatchToProps = dispatch => (
  {
    onLogout: () => { dispatch(logoutUser()); },
  }
);

class Error404 extends PureComponent {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
  }

  handleLogout = () => {
    const { onLogout } = this.props;
    onLogout();
  }

  renderButtons = () => (
    <div className="flex justify-center justify-start-l flex-row-ns flex-column">
      <Button
        className="self-center mr3-ns mb0-ns mb3"
      >
        <Link to="/">Back to Home</Link>
      </Button>
      <Button
        onClick={this.handleLogout}
        className="self-center mr3-ns mb0-ns mb3"
      >
        Log Out
      </Button>
    </div>
  );

  render() {
    return (
      <div className="flex justify-center items-center flex-column w-100 h-100 relative pa3 errorPage mt6-l mt4">
        <div className="flex w-100 justify-center flex-row-l flex-column">
          <div className="w-100 flex justify-end-l justify-center">
            <img src={errorImage} alt="404" className="ph5 pb5 self-center" />
          </div>
          <div className="w-100 items-center justify-center flex tl-l tc">
            <div>
              <h1 className="f-subheadline b mb4">404</h1>
              <h2>Sorry the page you were looking for was not found.</h2>
              {this.renderButtons()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
)(Error404);
