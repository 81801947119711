import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { Loader } from '@keethealth/keet-ui';
import includes from 'lodash/includes';
import Layout from 'common/Layout';

import { getCurrentSession } from 'services/auth';
import { readProfile } from 'services/api';
import { logoutUser } from 'redux/auth/actions';
import Client from './client';

class AuthRoute extends Component {
  static propTypes = {
    isPublic: PropTypes.bool,
    path: PropTypes.string,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
    computedMatch: PropTypes.shape({
      path: PropTypes.string,
    }),
  };

  static defaultProps = {
    isPublic: false,
  };

  static defaultProps = {
    path: undefined,
    computedMatch: {},
  }

  state = {
    session: false,
    roles: [],
    waiting: true,
  }

  async componentDidMount() {
    await this.checkRole();
  }

  async componentWillReceiveProps(prevProps) {
    /**
     * Check the computed path variable.
     * Without this, each route change checks session and renders loader
     * Breaks flow on pages where states need to be passed between url parameters
     */
    if (prevProps.computedMatch.path !== this.props.computedMatch.path) {
      await this.checkRole();
    }
  }

  checkRole = async () => {
    this.setState({
      waiting: true,
    });
    let session;
    let roles = [];
    try {
      session = await getCurrentSession();
      if (session.accessToken) {
        const profile = await readProfile();
        if (profile) {
          roles = profile.data.attributes.roles;
        }
      }
    } catch (e) {
      session = false;
    }

    this.setState({
      session,
      roles,
      waiting: false,
    });
  }

  render() {
    const { isPublic, path, location } = this.props;
    const otherProps = this.props;
    const { session, roles, waiting } = this.state;

    // We want to blacklist some routes here that should
    // Redirect you to dashboard/root page if you already have
    // A valid token
    const redirectPaths = [
      '/',
    ];

    if (waiting) {
      return <Loader color="primary" />;
    }

    // If we're trying to reach a public route
    // we should only redirect if the route isn't part of the redirect list
    if (isPublic && !includes(redirectPaths, path)) {
      return <Route {...otherProps} />;
    }

    // If the user is a valid user
    if (session.accessToken) {
      // check if they have the correct roles for this app
      if (!includes(roles, 'patient')) {
        Client.getStore().dispatch(logoutUser());
        return <Redirect to={'/login'} />;
      }
      // If the route is public, redirect to private root.
      if (isPublic) {
        return <Redirect to={'/my_tasks'} />;
      }
      // If the route is private, proceed.
      return (
        <Layout>
          <Route {...otherProps} />
        </Layout>
      );
    }
    // If the route is private and user is not authenticated.
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { referrer: location.pathname },
        }}
      />
    );
  }
}

export default AuthRoute;
