import gql from 'graphql-tag';

export default gql`
  mutation markExerciseAsComplete($id: ID!) {
    completeExercise: completeEducation(input: {
      id: $id,
    }) {
      carePlanActivity {
        id
        status
      }
    }
  }
`;
