import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql, compose } from 'react-apollo';
import { Video } from '@keethealth/ui-components';

import queries from 'graph/queries';
import MARK_EXERCISE_AS_COMPLETE from 'graph/mutations/markExerciseAsComplete';
import { Button, Hexagon } from '@keethealth/keet-ui';
import { Link } from 'react-router-dom';
import { getExerciseQuantities } from 'utils/content';
import Panel from 'common/Panel';
import CompletedModal from 'common/CompletedModal';
import history from 'utils/history';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import { getBasePath, findNext } from 'utils/utils';
import { isCompleted } from 'utils/carePlans';
import './TaskDetail.css';

export function TaskDetail({ loading, match, getNextActivity, exercise, exercises, markExerciseAsComplete, carePlanActivity }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [complete, setComplete] = useState(false);
  const videoUrl = get(exercise, 'plannable.videoUrl');
  const { params: {taskId} } = match;

  useEffect(() => {
    if (exercise) {
      setComplete(isCompleted(exercise));
    }
  }, [exercise]);

  return (
    <>
      {!loading &&
        <Panel className="overflow-auto">
          <div className="f4 bb pa3 tc-ns tl pl5-ns pr5-ns relative">
            {history.location.pathname.startsWith('/my_tasks') && <Link to={getBasePath(history.location.pathname)}>
              <i className="fas fa-arrow-left absolute left-2-ns left-1 h-100 top-0 flex items-center" />
            </Link>}
            <div className="tc-ns tl pl5-ns pl4 pr5-ns">
              {exercise.plannable ? exercise.plannable.title : null}
            </div>
          </div>
          <div>
            <div className="flex flex-column items-center">
              {videoUrl && <Video url={videoUrl} controls className="ReactPlayer" />}
              {renderQuantities()}
              {exercise.description &&
                <div className="measure-wide ma3 w-100">
                  <h4>INSTRUCTIONS</h4>
                  <p>{exercise.description}</p>
                </div>
              }
              {complete ? (
                <div className="flex items-center flex-column mb5">
                  <div className="mb3"><i className="primary f2 fas fa-check-circle" /></div>
                  <div className="gray">Completed</div>
                </div>
              ) : (
                <Button
                  className="center mb5 mt3"
                  onClick={handleComplete}
                  disabled={complete}
                >
                  Complete
                </Button>
              )}
            </div>
          </div>
          <CompletedModal isOpen={isModalOpen} onClose={handleEnd} />
        </Panel>
      }
    </>
  );

  function getNext() {
    if (!isEmpty(exercises)) {
      const currentIndex = findIndex(exercises, { id: taskId });
      const nextActivity = findNext(exercises, currentIndex, (cpa) => cpa.status !== 'completed');

      if (nextActivity) return nextActivity.id;
    }
    // return to beginning
    return null;
  }

  function handleEnd() {
    let basePath = getBasePath(history.location.pathname);
    const nextActivityId = basePath.includes('my_tasks') ? getNextActivity() : getNext();

    if (nextActivityId) {
      basePath = `${basePath}/${nextActivityId}`;
    }
    history.push(basePath);
  }

  function handleComplete(e) {
    e.preventDefault();
    const basePath = getBasePath(history.location.pathname);
    markExerciseAsComplete(exercise.id);
    const nextActivityId = basePath.includes('my_tasks') ? getNextActivity() : getNext();
    if (isEmpty(nextActivityId)) {
      setIsModalOpen(true);
    } else {
      handleEnd();
    }
  }

  function renderQuantities() {
    const quantities = getExerciseQuantities(carePlanActivity.properties);

    return (
      <div className="w-100 pa3 bg-white bt bb">
        <div className="flex mw8 center flex justify-around taskDetails">
          {map(quantities, (quantity) => (
            <div className="flex flex-column w-100">
              <Hexagon
                color="primary"
                label={get(quantity, 'value') || '-'}
              />
              <span className="db tc mv2">{quantity.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

TaskDetail.propTypes = {
  loading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  getNextActivity: PropTypes.func,
  exercise: PropTypes.shape({
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    plannable: PropTypes.shape({
      videoUrl: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  }),
  exercises: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    plannable: PropTypes.shape({
      videoUrl: PropTypes.string,
      title: PropTypes.string.isRequired,
    }),
  })),
  markExerciseAsComplete: PropTypes.func.isRequired,
  carePlanActivity: PropTypes.shape({
    properties: PropTypes.shape({
      count: PropTypes.number,
      duration: PropTypes.number,
      durationUnit: PropTypes.oneOf(['Second', 'Minute', 'Hour']),
      frequency: PropTypes.number,
      period: PropTypes.number,
      periodUnit: PropTypes.oneOf(['Hour', 'Day', 'Week']),
    }),
  }),
};

TaskDetail.defaultProps = {
  videoUrl: '',
  exercise: null,
  exercises: null,
  carePlanActivity: {
    properties: {},
  },
  getNextActivity: () => {},
};

export default compose(
  graphql(MARK_EXERCISE_AS_COMPLETE, {
    props: ({ mutate }) => ({
      markExerciseAsComplete: (id) => mutate({
        variables: { id },
      }),
    }),
  }),
  graphql(queries.exercise, {
    props: ({ data: { loading, exercise } }) => ({
      loading,
      exercise,
    }),
    options: ({ match: { params: { taskId: id } } }) => ({
      variables: { id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(queries.exercises, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { exercises } }) => ({
      exercises,
    }),
  }),
)(TaskDetail);
