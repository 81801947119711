import gql from 'graphql-tag';
import CONTENT_FRAGMENT from './content';

export default gql`
  fragment exercise on Exercise {
    count
    frequency
    duration
    durationUnit
    period
    periodUnit
    videoUrl
    photoUrl
    ...content
  }
  ${CONTENT_FRAGMENT}
`;
