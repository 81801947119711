import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@keethealth/keet-ui';
import { withRouter } from 'react-router-dom';
import history from 'utils/history';
import { graphql, compose } from 'react-apollo';
import { logoutUser } from 'redux/auth/actions';
import { connect } from 'react-redux';
import CURRENT_USER from 'graph/queries/currentUser';
import '../error_page.css';

const mapDispatchToProps = dispatch => (
  {
    onLogout: () => { dispatch(logoutUser()); },
  }
);

class ErrorPage extends PureComponent {
  static propTypes = {
    onLogout: PropTypes.func.isRequired,
    onChangeErrorKey: PropTypes.func.isRequired,
  }

  handleRefresh = () => {
    window.location.reload();
  }

  handleLogout = () => {
    const { onLogout, onChangeErrorKey } = this.props;
    onChangeErrorKey();
    onLogout();
  }

  handleHome = () => {
    const { onChangeErrorKey } = this.props;
    onChangeErrorKey();
    history.push('/my_tasks');
  }

  renderButtons = () => (
    <div className="flex">
      <Button
        className="flex mr3"
        onClick={this.handleHome}
      >
        Back to Home
      </Button>
      <Button
        className="flex mr3"
        onClick={this.handleRefresh}
      >
        Refresh Page
      </Button>
      <Button
        onClick={this.handleLogout}
      >
        Log Out
      </Button>
    </div>
  );

  render() {
    return (
      <div className="flex justify-center items-center flex-column w-100 h-100 absolute top-0 pa3 errorPage">
        <h1>Something went wrong.</h1>
        <h2>If this error persists, please contact customer support.</h2>
        {this.renderButtons()}
      </div>
    );
  }
}

export default compose(
  connect(null, mapDispatchToProps),
  withRouter,
  graphql(CURRENT_USER, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { currentUser } }) => ({
      currentUser,
    }),
  }),
)(ErrorPage);
