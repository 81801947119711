import gql from 'graphql-tag';

import MESSAGE_FRAGMENT from 'graph/fragments/message';

export default gql`
  mutation readMessage($input: MessageReadInput!) {
    readMessage(input: { message: $input }) {
      message {
        ...message
      }
    }
  }
  ${MESSAGE_FRAGMENT}
`;
