import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line } from 'recharts';
import moment from 'moment';
import { graphql } from 'react-apollo';
import sortBy from 'lodash/sortBy';
import queries from 'graph/queries';

class ProgressGraph extends Component {
  static propTypes = {
    questionnaireResponses: PropTypes.arrayOf(PropTypes.shape({
      attributes: PropTypes.shape({
        authored: PropTypes.string,
        scores: PropTypes.string,
      }),
    })),
  }

  static defaultProps = {
    questionnaireResponses: [],
  }

  render() {
    const { questionnaireResponses } = this.props;

    const sortedQuestionnaireResponses = sortBy(questionnaireResponses, 'authored');

    const sortedData = sortedQuestionnaireResponses.map(response => ({
      date: moment(response.authored).format('YYYY-MM-DD'),
      Score: parseInt(response.scores, 10),
    }));

    return (
      <ResponsiveContainer width="100%" height={350}>
        <LineChart
          data={sortedData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Line type="monotone" dataKey="Score" stroke="#D81F7B" />
        </LineChart>
      </ResponsiveContainer>
    );
  }
}

export default graphql(queries.questionnaireResponses, {
  options: ({ questionnaireId }) => ({
    variables: { questionnaireId },
    fetchPolicy: 'cache-and-network',
  }),
  props: ({ data: { loading, questionnaireResponses } }) => ({
    questionnaireResponses,
    loading,
  }),
})(ProgressGraph);
