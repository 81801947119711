import gql from 'graphql-tag';

export default gql`
  mutation changePassword(
    $input: ChangePasswordInput!,
  ) {
    changePassword(input: $input) {
      errors {
        path
        message
      }
    }
  }
`;
