import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-md';
import cN from 'classnames';
import './textfield.css';

export class Text extends Component {
  static defaultProps = {
    className: '',
    disabled: false,
  }

  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
  }

  render() {
    const { className, ...rest } = this.props;
    return (
      <TextField
        {...rest}
        className={cN('w-100', className)}
      />
    );
  }
}

export default Text;
